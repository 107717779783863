// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-avc-js": () => import("./../../../src/pages/projects/avc.js" /* webpackChunkName: "component---src-pages-projects-avc-js" */),
  "component---src-pages-projects-bdmyshopi-js": () => import("./../../../src/pages/projects/bdmyshopi.js" /* webpackChunkName: "component---src-pages-projects-bdmyshopi-js" */),
  "component---src-pages-projects-biocheck-js": () => import("./../../../src/pages/projects/biocheck.js" /* webpackChunkName: "component---src-pages-projects-biocheck-js" */),
  "component---src-pages-projects-bofisc-js": () => import("./../../../src/pages/projects/bofisc.js" /* webpackChunkName: "component---src-pages-projects-bofisc-js" */),
  "component---src-pages-projects-christmasanimation-js": () => import("./../../../src/pages/projects/christmasanimation.js" /* webpackChunkName: "component---src-pages-projects-christmasanimation-js" */),
  "component---src-pages-projects-ipvdiergeneeskunde-js": () => import("./../../../src/pages/projects/ipvdiergeneeskunde.js" /* webpackChunkName: "component---src-pages-projects-ipvdiergeneeskunde-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-logoanimation-js": () => import("./../../../src/pages/projects/logoanimation.js" /* webpackChunkName: "component---src-pages-projects-logoanimation-js" */),
  "component---src-pages-projects-projectworkflow-js": () => import("./../../../src/pages/projects/projectworkflow.js" /* webpackChunkName: "component---src-pages-projects-projectworkflow-js" */),
  "component---src-pages-projects-salonstenboogaerde-js": () => import("./../../../src/pages/projects/salonstenboogaerde.js" /* webpackChunkName: "component---src-pages-projects-salonstenboogaerde-js" */),
  "component---src-pages-projects-shanazrazik-js": () => import("./../../../src/pages/projects/shanazrazik.js" /* webpackChunkName: "component---src-pages-projects-shanazrazik-js" */),
  "component---src-pages-projects-shoptityone-js": () => import("./../../../src/pages/projects/shoptityone.js" /* webpackChunkName: "component---src-pages-projects-shoptityone-js" */),
  "component---src-pages-projects-vertigo-js": () => import("./../../../src/pages/projects/vertigo.js" /* webpackChunkName: "component---src-pages-projects-vertigo-js" */),
  "component---src-pages-projects-woonhaven-js": () => import("./../../../src/pages/projects/woonhaven.js" /* webpackChunkName: "component---src-pages-projects-woonhaven-js" */),
  "component---src-pages-projects-zorgband-js": () => import("./../../../src/pages/projects/zorgband.js" /* webpackChunkName: "component---src-pages-projects-zorgband-js" */)
}

